import "./grilleaccueil.css";
const GrilleAccueil = () => {
  return (
    <div className="grilleAccueilContainer">
      <div className="grille123Container">
        <a className="grille1Container" href="/">
          <div className="grille">
            <p className="pGrille">
              Créer votre <br /> site web <br /> sur mesure
            </p>
          </div>
        </a>
        <a className="grille2Container" href="/contact">
          <div className="grille">
            <p className="pGrille">
              Nous <br /> Contacter
            </p>
          </div>
        </a>
        <a className="grille3Container" href="/nosservices">
          <div className="grille">
            <p className="pGrille">Nos Services</p>
          </div>
        </a>
      </div>
      <div className="grille456Container">
        <a className="grille4Container" href="/template">
          <div className="grille">
            <p className="pGrille">
              Nos Modèles <br /> de site à <br />
              30€/mois
            </p>
          </div>
        </a>
        <a className="grille5Container" href="/blogdvpariege">
          <div className="grille">
            <p className="pGrille">
              Le Blog <br /> dvpariege
            </p>
          </div>
        </a>
        <a className="grille6Container" href="/">
          <div className="grille">
            <p className="pGrille">....</p>
          </div>
        </a>
      </div>
    </div>
  );
};
export default GrilleAccueil;
