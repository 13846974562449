import "./pageblogdvpariege.css";
import eBook from '../../images/ebooknaturosport.png';
import imgluzenac from "../../images/blog/photo/luzenac1.jpg";
import imgargein from "../../images/blog/photo/argein.jpg";
import imgvilleneuve from "../../images/blog/photo/villeneuve.jpg";
import imgluzenac2 from "../../images/blog/photo/luzenac2.jpg";
import imgargein2 from "../../images/blog/photo/argein2.jpg";
import imgchat from "../../images/blog/photo/chat.jpg";
import islande1 from "../../images/blog/voyage/islande/islande.jpg"
import islande2 from "../../images/blog/voyage/islande/DSC03984.jpg"
import islande3 from "../../images/blog/voyage/islande/DSC04161.jpg"
import islande4 from "../../images/blog/voyage/islande/DSC04168.jpg"
import islande5 from "../../images/blog/voyage/islande/DSC04245.jpg"
import islande6 from "../../images/blog/voyage/islande/DSC04247.jpg"
import islande7 from "../../images/blog/voyage/islande/DSC04307.jpg"
import islande8 from "../../images/blog/voyage/islande/DSC04375.jpg"
import islande9 from "../../images/blog/voyage/islande/DSC04383.jpg"
import islande10 from "../../images/blog/voyage/islande/DSC04473.jpg"
import islande11 from "../../images/blog/voyage/islande/DSC04566.jpg"
import islande12 from "../../images/blog/voyage/islande/DSC04682.jpg"
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import Galerie from "../../components/galerievoyage/GalerieVoyage";
import GalerieInsta4Images from "../../components/galerieinsta4images/GalerieInsta4Images";
import maroc1 from "../../images/blog/maroc/maroc1.jpg";
import maroc2 from "../../images/blog/maroc/maroc2.jpg";
import maroc3 from "../../images/blog/maroc/maroc3.jpg";
import maroc4 from "../../images/blog/maroc/maroc4.jpg";
import maroc5 from "../../images/blog/maroc/maroc5.jpg";
import maroc6 from "../../images/blog/maroc/maroc6.jpg";
import forgeron1 from "../../images/blog/insta/forge/forge1.png";
import forgeron2 from "../../images/blog/insta/forge/forge2.png";
import forgeron3 from "../../images/blog/insta/forge/forge3.png";
import forgeron4 from "../../images/blog/insta/forge/forge4.png";
import bois1 from "../../images/blog/insta/artisanat bois/bois1.png";
import bois2 from "../../images/blog/insta/artisanat bois/bois2.png";
import bois3 from "../../images/blog/insta/artisanat bois/bois3.png";
import bois4 from "../../images/blog/insta/artisanat bois/bois4.png";
import ArticleYouTube from "../../components/articleyoutube/ArticleYouTube";
import CarteInformations6 from "../../components/carteinformations6/CarteInformations6";
import espace from '../../images/blog/insta/illustration/imginstaespace.png';
import sushi from "../../images/blog/insta/illustration/illustrationsushi.png";
import Footer from "../../components/footer/Footer";
const PageBlogDvpAriege = () => {
  return (
    <div className="PageBlogDvpAriegeContainer">
      <MenuOuvrant />
      <div
        className="imgPresentationBlog"
        alt="mélange dimg avec des drone appareil.p café ordinateur et le logo de dvpariege"
      />
       <div className="eBookContainer">
        <img className="eBookImg" src={eBook} alt="eBook Naturosport" />
        <br/>
        <br/>
        <h1 className="h1Ebook">eBook GRATUIT à télécharger dès maintenant !</h1>
        <h2 className="h2Ebook">Vous souhaitez en savoir plus sur la naturopathie et son application au basketball ? Téléchargez gratuitement l'eBook de sbnaturosport en cliquant ici</h2>
       <div className="lienSbNaturoSport">
        <a className="aSbNaturoSport" href="https://www.sbnaturosport.fr/ebookNaturosport">sbnaturosport</a>
       </div>
       </div>
      <Galerie 
      titre1="2024"
      soustitre= "photographie"
      img1={imgluzenac}
      img2={imgargein}
      img3={imgvilleneuve}
      img4={imgluzenac2}
      img5={imgargein2}
      img6={imgchat}
      />
        <Galerie
        titre1="Islande"
        soustitre="Islande : A la découverte de ses paysages"
        img1={islande1}
        img2={islande2}
        img3={islande3}
        img4={islande4}
        img5={islande5}
        img6={islande6}
      />
      <div className="imgBlogContainerPlus">
       <img className="imgGalerie" alt="islande" src={islande7} />
       <img className="imgGalerie" alt="islande" src={islande8} />
       <img className="imgGalerie" alt="islande" src={islande9} />
       <img className="imgGalerie" alt="islande" src={islande10} />
       <img className="imgGalerie" alt="islande" src={islande11} />
       <img className="imgGalerie" alt="islande" src={islande12} />
      </div>
       <CarteInformations6 
              titre="Partageons un instant créatif !"
              texte="Je publie parfois sur Instagram des illustrations que je réalise sur différents thèmes, juste pour le plaisir de créer et de partager."
              img={espace} 
       />
      <GalerieInsta4Images
        titre1="Les métiers de la région"
        soustitre="Le métier de forgeron"
        img1={forgeron1}
        img2={forgeron2}
        img3={forgeron3}
        img4={forgeron4}
      />
      <Galerie
        titre1="Voyage Maroc"
        soustitre="oujda"
        img1={maroc1}
        img2={maroc2}
        img3={maroc3}
        img4={maroc4}
        img5={maroc5}
        img6={maroc6}
      />
      <div className="pourInfoContainer">
        <p className="pPourInfo">
          Oujda, Maroc : Une destination culturelle et historique Oujda est une
          ville du nord-est du Maroc, située à la frontière avec l'Algérie. Elle
          est la capitale de la région de l'Oriental. La ville a une longue et
          riche histoire, qui remonte à l'époque romaine. Elle a été
          successivement occupée par les Arabes, les Ottomans et les Français.
        </p>
        <div className="videoYouTubeContainer">
          <div className="descriptionYoutubeEtVideoContainer">
            <p className="descriptionChaineYoutube">
              La chaîne YouTube de GaGzZz est une chaîne francophone consacrée
              aux jeux vidéo et au game design. Elle est animée par Gaëtan, un
              game designer de profession qui a travaillé pour des studios tels
              qu'Ubisoft, Arkane Studios et Wolfeye Studios. Les vidéos de la
              chaîne sont variées et couvrent un large éventail de sujets. On y
              trouve des tests de jeux vidéo, des let's plays, des analyses de
              gameplay, des discussions sur les tendances du jeu vidéo, et des
              réflexions sur le game design.
            </p>
            <iframe
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/nHAWyMIWSFA"
              title="STAR WARS OUTLAWS, un BON JEU sans JEDI???"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <a className="aYouTube" href="https://www.youtube.com/@Gagzzz82">
            Voir la chaîne de GaGzZz
          </a>
        </div>
        <ArticleYouTube
          description="Benjamin Code est une chaîne francophone
       consacrée au développement web et mobile. Elle est animée par
       Benjamin, un développeur web et mobile expérimenté qui partage ses
       connaissances et ses expériences avec sa communauté. Les vidéos de
       la chaîne sont variées et couvrent un large éventail de sujets. On
       y trouve des tutoriels, des conseils, des interviews, des
       discussions sur les tendances du développement web et mobile, et
       des réflexions sur la carrière de développeur."
          lienchaine="https://www.youtube.com/@BenjaminCode"
        />
      </div>
      <CarteInformations6 
              titre="Partageons un instant créatif !"
              texte="Je publie parfois sur Instagram des illustrations que je réalise sur différents thèmes, juste pour le plaisir de créer et de partager."
              img={sushi} />
      <GalerieInsta4Images
        titre1="Les métiers de la région"
        soustitre="Le métier de forgeron"
        img1={bois1}
        img2={bois2}
        img3={bois3}
        img4={bois4}
      />
    <Footer />
    </div>
  );
};
export default PageBlogDvpAriege;
