import '../header/header.css'
const Header = () => {
    return(
      <header className="allHeader">
          <nav>
          <ul className="ulContainer">
            <li><a className='aHeader' href='https://www.dvpariege.fr/'>Menu principal</a></li>
            <li><a  className='aHeader' href='https://www.dvpariege.fr/nosservices'>Nos services</a></li>
            <li><a className='aHeader ' href='https://www.dvpariege.fr/creationdvp'>Créations dvp </a></li>
          </ul>
          </nav>    
        </header>
    )
}

export default Header