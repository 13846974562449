import './imgTxtDeux.css'
const ImgTxtDeux = (props) => {
    return(
        <div className="allImgTxtDeux">
            <div className='h1Container'>
          <h1 className='h1ImgTxt'>{props.titre}</h1>
            </div>
          <div className="allImgContainer">
            <div className="allImg">
            <img className="img1" alt="img association" src={props.imagecarte1}/>
            <h2 className='h2Carte'>{props.titrecarte1}</h2>
            <p className='pImgTxtDeux'>{props.textecarte1}</p>
            <p className='pImgTxtDeux'>{props.textecarte2}</p>
            </div>
            <div className='allImg'>
            <img className="img1" alt="img association" src={props.imagecarte3}/>
            <h2 className='h2Carte'>{props.titrecarte3}</h2>
            <p className='pImgTxtDeux'>{props.textecarte3}</p>
            <p className='pImgTxtDeux'>{props.textecarte4}</p>
          </div>
          </div>
        </div>
    )
}
export default ImgTxtDeux