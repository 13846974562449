import '../notfound/notfound.css'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return(
        <div className="allPageNotFound">
          <h1 className='h1Error404'> Erreur 404 <br /> Page Introuvable</h1>
          <Link className='link404' to="/">Revenir à la page d'accueil</Link>
        </div>
    )
}
export default NotFound