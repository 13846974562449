import "./maintenance.css";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import Footer from "../../components/footer/Footer";
const Maintenance = () => {
  return (
    <div className="allPageMaintenance">
    <MenuOuvrant />
    <div className="pDvpContainer">
      <p>DVP</p>
    </div>
      <h1 className="h1OfferMaintenance">
        Besoin de <br /> maintenir votre site <br /> automatiquement <br /> en
        ligne ?{" "}
      </h1>
      <div className="choiceOfferContainer">
        <div className="choice1Container">
          <p className="pChoice1">Classique : 30 euros/mois</p>
        </div>
        <div className="choice2Container">
          <p className="pChoice2">Performance : 90 euros/mois</p>
        </div>
      </div>
      <div className="descriptionChoiceContainer">
      <div className="descriptionChoice1">
        <h2>OFFRE CLASSIQUE</h2>
        <p>
        Avec l'offre classique maintenez votre code à jour automatiquement pour garder un site stable et gagner un temps considérable pour vos futures mises à jour
        </p>
      </div>
      <div className="descriptionChoice2">
        <h2 className="h2DescriptionChoice2">OFFRE PERFORMANCE</h2>
        <p>
        Avec l'offre Performance vous avez les mêmes avantages que dans le pack classique, 
        mais vous disposez aussi d'une optimisation de votre site et d'un référencement de qualité, nous vous proposons également de modifier ou ajouter du contenu gratuitement
        </p>
      </div>
      </div>
      <Footer />
    </div>
  );
};
export default Maintenance;
