import "./pageprincipale.css";
import CardsPagePrincipale from "../../../components/compotemplate/cardsPagePrincipale/CardsPagePrincipale";
import Header from "../../../components/compotemplate/header/Header"
import Footer from "../../../components/compotemplate/footer/Footer";
import logotemplate from "../../../images/imgtemplate/logocoiffure.png";
const PagePrincipale = () => {
  return (
    <div className="PagePrincipaleContainer">
      <Header 
      h1header="Les ciseaux d'or"
      soustitre="coiffure • barbier • styliste"
      logo={logotemplate}
      />
      <CardsPagePrincipale
        titremenu1="Nos services"
        titremenu2="contacter nous"
        titremenu3="Notre galerie dimages"
      />
      <Footer
       titre="CoifAir"
       soustitre="coiffure • barbier • styliste"
       tel="06.00.00.00.00"
       mail="lesciseauxdor@gmail.com"
       adresse="14 avenue de la République 09000 Foix"
      logo={logotemplate}
      />
    </div>
  );
};
export default PagePrincipale;