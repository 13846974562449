import "./creationdvp.css";
import groupe from "../../images/groupe.png";
import flechebascreer from "../../images/flechebas.png";
import nosoutils from "../../images/backgroundNosOutils.png";
import InfoServices from "../../components/infoservices/InfoServices";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import Footer from "../../components/footer/Footer";
const Creationdvp = () => {
  return (
    <div className="allCreation">
      <div className="headerContainer">
        <MenuOuvrant />
      </div>
      <div className="part1">
      <img
          className="nosOutils"
          src={nosoutils}
          alt="img titre et logo dev web"
        />
      </div>
      <div className="pConfianceContainer">
        <img
          className="flecheBasCreer"
          src={flechebascreer}
          alt="img fleche orange"
        />
        <p className="confiance">
          Faites nous confiance pour développer votre projet et mettre en place
          la meilleure stratégie pour mettre en avant vos offres et vos produits
          avec un site internet dédié à vos besoins.
        </p>
      </div>
      <div className="tabContainer">
        <div className="tab1">
          <ul className="ulTab">
            <li className="liReact">react</li>
            <li className="liReact2">Développement sur mesure</li>
            <li className="liReact2">Évolutivité</li>
            <li className="liReact2">Sécurité</li>
          </ul>
        </div>
        <div className="tab2">
          <ul className="ulTab">
            <li className="liWordpress">wordpress</li>
            <li className="liReact2">Prix attractif</li>
            <li className="liReact2">Rapidité</li>
            <li className="liReact2">Facile à utiliser</li>
          </ul>
        </div>
      </div>
      <img className="imgGroupe" src={groupe} alt="img groupe en entreprise" />
      <div className="imgContainer">
      <div className="imgMaintenanceContainer">
        <p className="pOutils">
        En partant de la sélection des outils de développement du choix du design de votre page web ou de la prise d'image et de vidéos, nous vous aiderons pour faire le meilleur choix et vous accompagnerons du début du projet jusqu'à sa livraison finale. Et si vous le désirez, nous continuerons à maintenir votre site à jour avec notre offre de maintenance qui vous assure un site stable, sécurisé, et bien référencé.
        </p>
        <InfoServices />
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default Creationdvp;
