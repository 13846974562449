import "./infoservices.css";
import imgentreprise from "../../images/imgentreprise.png";
import imgsecurite from "../../images/imgsecurite.svg";
import imgresponsive from "../../images/imgresponsive.svg";
import videocontact from "../../videos/videocontact.mp4";
const InfoServices = () => {
  return (
    <div className="servicesContainer">
      <h2 className="h2InfoServices">
        Création de sites internet sécurisés et performants <br />
        pour PME, Start-Up, associations, indépendants et freelance
      </h2>
      <img src={imgentreprise} className="imgEntreprise" alt="un homme et une femme qui discute devant un ordinateur qui contient des lignes de code  "/>
      <p className="texteServices">
        Vous êtes une PME, une Start-Up, une association, un indépendant ou un
        freelance ?<br /> Vous souhaitez développer votre activité sur internet
        et augmenter votre visibilité ?<br />
        La création d'un site internet est la première étape indispensable pour
        atteindre vos objectifs. Un site internet sécurisé et performant vous
        permettra de communiquer efficacement avec vos clients et prospects, de
        présenter vos produits ou services, et de générer des leads.
        <br />
        Notre agence web vous propose des solutions de création de sites
        internet sur-mesure, adaptées à vos besoins et à votre budget. Nos
        experts vous accompagnent tout au long du projet, de la conception à la
        mise en ligne, pour vous garantir un site internet à la hauteur de vos
        attentes.
        <br />
      </p>
      <div className="servicesContainer1">
        <h2 className="h2InfoServices">Nos services</h2>
        <h2 className="h2InfoServices">Création de sitesinternet sécurisés</h2>
        <img
          className="imgSecurite"
          src={imgsecurite}
          alt="barre adresse avec https"
        />
        <p className="texteServices">
          Nos sites internet sont conçus sur des technologies de pointe et
          répondent aux dernières normes de sécurité. Ils sont ainsi protégés
          contre les cyberattaques et les intrusions.
        </p>

        <h2 className="h2InfoServices">
          Création de sites internet performants
        </h2>
        <p className="texteServices">
          Nos sites internet sont optimisés pour offrir une expérience
          utilisateur optimale, quel que soit le type d'appareil utilisé
          (ordinateur, smartphone, tablette).
          <br /> Ils sont également rapides à charger, ce qui est essentiel pour
          le référencement naturel.
        </p>
        <h2 className="h2InfoServices">Optimisation SEO</h2>

        <p className="texteServices">
          Nous optimisons votre site internet pour les moteurs de recherche
          (SEO). Cela vous permettra d'être mieux positionné dans les résultats
          de recherche et d'augmenter votre visibilité.
        </p>
      </div>
      <h2 className="h2InfoServices">Design responsive</h2>
      <img
        className="imgResponsive"
        src={imgresponsive}
        alt="un bureau avec un ordinateur une tablette et un smartphone"
      />
      <p className="texteServices">
        Votre site internet sera accessible et visible sur tous les supports{" "}
        <br /> ordinateurs, smartphones et tablettes.
      </p>
      <div className="servicesContainer2">
        <h2 className="h2InfoServices">Pour qui ?</h2>
        <p className="texteServices">
          Nos solutions de création de sites internet s'adressent à tous les
          types d'entreprises <br />
          quelle que soit leur taille, leur activité ou leur budget.
        </p>
        <h2 className="h2InfoServices">Contactez-nous </h2>
        <h2 className="h2InfoServicesTel">06 31 55 06 82</h2>
        <video className="videoContact"  autoPlay loop muted title="femme qui compose un numéro de téléphone sur son smartphone" aria-label="femme qui compose un numéro de téléphone sur son smartphone la vidéo dure 12 secondes le fond de la video est de couleur rose">
          <source src={videocontact} type="video/mp4" />
          </video>
        <p className="texteServices">
          {" "}
          Pour en savoir plus sur nos services ou pour obtenir un devis
          personnalisé, contactez-nous. Grâce à notre expertise et à notre
          savoir-faire, nous vous aiderons à développer votre activité sur
          internet et à augmenter votre visibilité.
        </p>
      </div>
    </div>
  );
};
export default InfoServices;
