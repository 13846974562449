import "./galerieVoyage.css";
import logovoyage from "../../images/blog/dvpcuisine.png";
const GalerieVoyage = (props) => {
  return (
    <div className="galerieContainer">
      <div className="logoTitreVoyageContainer">
        <img
          className="logoVoyage"
          src={logovoyage}
          alt="logo dvpariege avec un avion"
        />
        <div className="imgVoyageContainer">
          <div className="h1GalerieVoyageContainer">
      </div>
            <h1 className="h1GalerieVoyage">{props.titre1}</h1>
            <h1 className="h1Part2">{props.titre2}</h1>
          </div>
        </div>
        <div className="h2GalerieContainer">
          <h2 className="h2GalerieVoyage">{props.soustitre}</h2>
          <p className="descriptionGalerie">{props.description}</p>
        </div>
        <img className="imgGalerie" src={props.img1} alt="img" />
        <img className="imgGalerie" src={props.img2} alt="img" />
        <img className="imgGalerie" src={props.img3} alt="img" />
        <img className="imgGalerie" src={props.img4} alt="img" />
        <img className="imgGalerie" src={props.img5} alt="img" />
        <img className="imgGalerie" src={props.img6} alt="img" />
    </div>
  );
};
export default GalerieVoyage;
