import "./galerieinsta4images.css";
import logoGalerieInsta from "../../images/instagram.png";
const GalerieInsta4Images = (props) => {
  return (
    <div className="galerieContainerInsta">
      <a className="aGalerieContainerInsta" href="https://www.instagram.com/dvpariege">
        <img
          src={logoGalerieInsta}
          className="logoGalerieInsta"
          alt="logo instagram"
        />
      </a>
      <div className="imgContainerInsta">
        <div className="h1ContainerInsta">
          <h1 className="h1GalerieInsta4">{props.titre1}</h1>
          <h1 className="h1Part2">{props.titre2}</h1>
        </div>
        <div className="h2GalerieInstaContainer">
          <h2 className="h2GalerieInsta4">{props.soustitre}</h2>
          <p className="descriptionGalerie">{props.description}</p>
        </div>
        <div className="galerieInstaContainer">
          <img className="imgGalerieInsta4" src={props.img1} alt="img" />
          <img className="imgGalerieInsta4" src={props.img2} alt="img" />
          <img className="imgGalerieInsta4" src={props.img3} alt="img" />
          <img className="imgGalerieInsta4" src={props.img4} alt="img" />
        </div>
      </div>
    </div>
  );
};
export default GalerieInsta4Images;
