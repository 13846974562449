import "./pagecontact.css";
import imgcontact from "../../../images/template/basic/backgroundcontactcoiffure.png";
import logoapell from "../../../images/imgtemplate/logotel.png";
import logomail from "../../../images/imgtemplate/logomail.png";
import logoadresse from "../../../images/imgtemplate/logoadresse.png";
import Header from "../../../components/compotemplate/header/Header";
import logotemplate from "../../../images/imgtemplate/logocoiffure.png";
import Footer from "../../../components/compotemplate/footer/Footer";
const PageContact = (props) => {
  return (
    <div className="allPageContactTemplateContainer">
      <div className="headerTemplateContainer">
        <Header
          h1header="Les ciseaux d'or"
          soustitre="coiffure • barbier • styliste"
          logo={logotemplate}
        />
      </div>
    <div className="pageContactContainer">
      <div className="horairesContainer">
        <h1 className="h1Horaires">Horaires d'ouverture</h1>
        <p className="jour-de-la-semaine">Lundi</p>
        <p className="pHoraires">{props.lundi}</p>
        <p className="jour-de-la-semaine">Mardi</p>
        <p className="pHoraires">{props.mardi}</p>
        <p className="jour-de-la-semaine">Mercredi</p>
        <p className="pHoraires">{props.mercredi}</p>
        <p className="jour-de-la-semaine">Jeudi</p>
        <p className="pHoraires">{props.jeudi}</p>
        <p className="jour-de-la-semaine">Vendredi</p>
        <p className="pHoraires">{props.vendredi}</p>
        <p className="jour-de-la-semaine">Samedi</p>
        <p className="pHoraires">{props.samedi}</p>
        <p className="jour-de-la-semaine">Dimanche</p>
        <p className="pHoraires">{props.dimanche}</p>
      </div>
      <div className="imgLogoContainer">
        <img
          className="imgContact"
          src={imgcontact}
          alt="img logo de contact"
        />
        <div className="mailTelAdresseContainer">
          <div className="logoTexteContainer">
            <img className="logoTel" src={logoapell} alt="logo appel" />
            <p className="pInfo">{props.tel}</p>
          </div>
          <div className="logoTexteContainer">
            <img className="logoMail" src={logomail} alt="logo mail" />
            <p className="pInfo">{props.mail}</p>
          </div>
          <div className="logoTexteContainer">
            <img className="logoAdresse" src={logoadresse} alt="logo adresse" />
            <p className="pInfo">{props.adresse}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer
       titre="CoifAir"
       soustitre="coiffure • barbier • styliste"
       tel="06.36.65.65.65"
       mail="lesciseauxdor@gmail.com"
       adresse="14 avenue de la République 09000 Foix"
      logo={logotemplate}
      />
    </div>
  );
};
export default PageContact;
