import "../footer/footer.css";
import phone from "../../images/iconePhone.png";
import mail from "../../images/iconeMail.png";
import logodvpariege from "../../images/logodvpariege4.png";
const Footer = () => {
  return (
    <div className="filtreFooterDvpAriege">
    <footer className="allFooter">
      <div className="footerDownContainer">
        <div className="footerDvpAriegePart1">
        <img className="logoFooterDvpAriege" src={logodvpariege} alt="logoinsta" />
        </div>
        <div className="contactFooterContainer">
          <p className="pNousContacter">Me Contacter</p>
          <img src={phone} className="animationPhone" alt=" img icone phone" />
          <div className="numberContainer">
            <p className="pNumber">06 31 55 06 82</p>
          </div>
          <img src={mail} className="animationMail" alt="img icone mail" />
          <p className="pMail">dvpariege@gmail.com</p>
        </div>
        <ul className="listFooter">
          <a href="https://www.dvpariege.fr/nosclients">
            <li className="liFooterStudioPhoto">
              Les clients qui nous ont fait confiance
            </li>
          </a>
          <a href="https://www.dvpariege.fr/creationdvp">
            <li className="liFooterStudioPhoto">Création de sites internet</li>
          </a>
          <a href="https://www.dvpariege.fr/nosservices">
            <li className="liFooterStudioPhoto">Studio Photo</li>
          </a>
          <a href="https://www.dvpariege.fr/nosservices#drone">
            <li className="liFooterStudioPhoto">Photos/Vidéos par drone</li>
          </a>
          <a href="https://www.dvpariege.fr/cartedevisite">
            <li className="liFooterStudioPhoto">Création carte de visite</li>
          </a>
          <a href="https://www.dvpariege.fr/blogdvpariege">
            <li className="liFooterStudioPhoto">Le blog dvpariege</li>
          </a>
          <a href="https://www.dvpariege.fr/mentionslegales">
            <li className="liFooterStudioPhoto">Mentions légales</li>
          </a>
        </ul>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
