import "./contactdvpariege.css";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import logoDiscord from "../../images/logoDiscord.png";
import logoSlack from "../../images/logoSlack.png";
import CarteInfo from "../../components/cartesinformations/ImgTxtDeux";
import portable from "../../images/iconephonecontact.png";

const Contact = () => {
  return (
    <>
      <div className="allContactDvpAriege">
        <div className="headerContainer">
          <MenuOuvrant />
        </div>
        <CarteInfo
          titre="Contacter nous directement"
          imagecarte1="https://cdn.pixabay.com/photo/2017/11/17/09/37/finger-2956974_1280.jpg"
          titrecarte1="Mail"
          textecarte1="pour toutes questions ou demandes vous pouvez nous contacter par mail à l'adresse suivante"
          textecarte2="dvpariege@gmail.com"
          imagecarte3={portable}
          titrecarte3="Portable"
          textecarte3="vous pouvez aussi nous joindre aux numéros suivants"
          textecarte4="06.31.55.06.82"
        />
        <div className="rsContainer">
          <div className="discordSlackContainer">
            <div className="discordContainer">
              <p className="pContactDiscord">DISCORD</p>
              <a href="https://discord.com/" className="aDiscord">
                <img
                  src={logoDiscord}
                  className="logoDiscord"
                  alt="img logo discord"
                />
              </a>
            </div>
            <div className="slackContainer">
              <p className="pContact">SLACK</p>
              <a
                href="https://app.slack.com/client/T046H5HRREC/C046H5P0D6C"
                className="aSlack"
              >
                <img
                  className="logoSlack"
                  src={logoSlack}
                  alt="img logo slack"
                />
              </a>
            </div>
          </div>
          <div className="phrase2ContactContainer">
            <p className="phrase2Contact">
              En utilisant slack ou discord nous facilitons le dialogue, cela
              permet également de partager nos idées pour avoir une vision
              commune et concrétisé votre projet dans les meilleures conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
