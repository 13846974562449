import "./articleyoutube.css";
const ArticleYouTube = (props) => {
  return (
    <div className="videoYouTubeContainer">
      <div className="descriptionYoutubeEtVideoContainer">
        <p className="descriptionChaineYoutube">{props.description}</p>
        <iframe
          width="1280"
          height="720"
          src="https://www.youtube.com/embed/Vq2L8LZAgow"
          title="L&#39;effet le plus fou de 2023 (et plein d&#39;autres)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
      <a className="aYouTube" href={props.lienchaine}>
        Voir la chaîne de Benjamin Code
      </a>
    </div>
  );
};
export default ArticleYouTube;
