import "../cartedevisite/carteDeVisite.css";
import Header from "../../components/header/Header";
import imgCreationCarte from "../../images/imgcreationcarte.png";
import logosimgtextcontainer from '../../images/logosimgtextcontainer.png';
const CarteDeVisite = () => {
  return (
    <div className="allCarteDeVisite">
      <Header />
      <div className="imgTextContainer">
        <div className="h1ContainerCarteDeVisite">
        <h1 className="h1Text"> Nous pouvons créer pour vous le design de votre carte de visite</h1>
        </div>
        <div className="imgCreationCarteContainer">
          <img className="imgCreationCarte" src={imgCreationCarte} alt="img" />
        </div>
        <div className="textContainer">
          <p className="text">   Afficher le logo de votre entreprise vos horaires et vos coordonnées, que ce soit en conférence en réunion ou dans n'importe quel lieu public, vous pourrez donner rapidement toutes les informations utiles concernant votre entreprise  </p>
          <img className="logosImgTextContainer" src={logosimgtextcontainer} alt="img logo contact" />
        </div>
      </div>
    </div>
  );
};
export default CarteDeVisite;
