import "./nosServices.css";
import studio1 from "../../images/studio1.png";
import studio2 from "../../images/studio2.png";
import drone from "../../images/drone.png";
import imgDrone from "../../images/backgroundDrone.png";
import imgKitDrone from "../../images/kitDrone.png";
import logoSafe from "../../images/logoSafe.png";
import maintenancelogo from "../../images/maintenancelogo.png";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
const NosServices = () => {
 

  return (
    <div className="allNosServices">
      <div className="headerContainer">
        <MenuOuvrant />
      </div>
      <p className="pTitleNosServices">photos studio</p>
      <h1 className="h1NosServices">
        Photographier <br /> vos produits
      </h1>
      <p className="pNosServices">
        DVP Ariège vous propose de mettre en valeur vos <br /> produits avec des photos
        de qualité.
      </p>
      <img className="imgStudio1" src={studio1} alt="img studio phot" />
      <img className="imgStudio2" src={studio2} alt="img studio phot" />
      <div id="drone">
        <h2 className="h2NosServices">
          Photos et vidéos en haute <br /> définition avec un drone
        </h2>
      </div>
      <p className="pNosServices">
        Prise de photos et de vidéos avec une  excellente résolution grâce à notre
        drone
      </p>
      <img className="imgDrone" src={drone} alt="img drone" />
      <img className="imgLuzenac" src={imgDrone} alt="img Montagne" />
      <img className="imgKitDrone" src={imgKitDrone} alt="img kit de drone" />
   
      <div className="allMaintenanceContainer">
      <div className="allMaintenance">
        <h2 className="h2Maintenance">
          Maintenance et mise à jour de <br /> votre site internet sur mesure
        </h2>
        <div className="logoSafeContainer">
          <img
            className="logoSafe"
            src={logoSafe}
            alt="img logo sécurité safe"
            type="img/svg"
          />
        </div>
        <p className="pMaintenance">
        Avec notre offre de maintenance, nous vous garantissons la stabilité et la fluidité <br /> de votre site avec un code mis à jour régulièrement pour une sécurité accru et un <br /> référencement de qualité.
        </p>
        <img
          src={maintenancelogo}
          className="logosMaintenance"
          alt="img logo reglage, sécurité, chrono "
        />
        <div className="buttonLinkMaintenance">
          <a href="https://www.dvpariege.fr/maintenance">
            <button className="buttonMaintenance">Consulter nos offres</button>
          </a>
        </div>
      </div>
      </div>
    </div>
  );
};
export default NosServices;
