import "./PageNosClients.css";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import logodvp from '../../images/logodvpariege.png';
const PageNosCLients = () => {
  return (
    <div className="allPageNosClients">
      <MenuOuvrant />
        <h1 className="h1NosClients">"Merci de m'avoir fait confiance"</h1>
      <div className="allEntrepriseContainer">
        <div className="entreprise1Container">
          <a href="https://mediumchantalenergie.com/" className="pNomEntreprise">MediumChantalEnergie</a>
          <a href="https://www.sbnaturosport.fr/" className="pNomEntreprise">SBNaturoSPort</a>
          <a href="https://www.les-bains-douches-pamiers.fr/" className="pNomEntreprise">Restaurant Les Bains Douches</a>
          <a href="https://mairie-tarasconsurariege.fr/" className="pNomEntreprise">La Mairie de Tarascon-sur-Ariège</a>
        </div>
      </div>
      <div className="h2ClientsContainer">
      </div>
        
          <ul className="ulCascade">
            <li className="liCascade"> Pour votre confiance</li>
            <li className="liCascade"> Pour votre professionnalisme</li>
            <li className="liCascade2">Merci beaucoup</li>
          </ul>
          <img src={logodvp} alt="img" className="logoDvpClients" />
          <p>dvpariege création de site internet </p>
        <h2 className="h2Clients">
          dvpariège est fier de représenter vos entreprises à travers ces sites
          internet et de leur offrir une visibilité supplémentaire qui permet à
          tout le monde de découvrir le savoir-faire de la région.
        </h2>
    </div>
  );
};
export default PageNosCLients;
