import "../menuprincipal/menuprincipal.css";
import logodvp from "../../images/logodvpariege4.png";
import imgblog from "../../images/imgblog2.png";
import imgrejoindreleblog from "../../images/imgrejoindreleblog.png";
import exempletemplate1 from "../../images/exempletemplate.png";
import GrilleAccueil from "../../components/grilleaccueil/GrilleAccueil";
import videoaccueil from "../../videos/videoaccueil.mp4";
import Footer from "../../components/footer/Footer";
import MenuOuvrantDvpAriege from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
//NOEL
// noel https://cdn.pixabay.com/photo/2012/04/25/00/47/christmas-tree-41448_1280.png
// noel https://cdn.pixabay.com/photo/2013/07/13/13/22/red-160898_1280.png
// noel contact https://cdn.pixabay.com/photo/2013/07/13/13/21/snowman-160868_1280.png

const MenuPrincipal = () => {
  return (
    <div className="allMp">
      <div className="allPageMenuPrincipal">
        <div className="imgBlogContainer">
          <div className="menuOuvrantDvpAriegeContainer">
            <MenuOuvrantDvpAriege />
          </div>
          <h1 className="h1Accueildvpariege">dvpariege</h1>

          <div className="sousTitreAccueil">Création de site internet</div>
        </div>
        <video className="videoAccueil" autoPlay loop muted>
          <source src={videoaccueil} type="video/mp4" />
        </video>
        <div className="h1pContainer">
          <h1 className="h1ADecouvrir">À Découvrir</h1>
          <p className="pADecouvrir">le blog et le premier template 2024!!!</p>
        </div>
        <div className="container1">
          <img
            src={imgblog}
            alt="voyage d'appareil.p de drone et le logo dvpariege "
            className="imgBlog"
          />
          <img
            src={imgrejoindreleblog}
            className="imgRejoindreLeBlog"
            alt="deux personnes qui se serrent la main"
          />
        </div>
        <div className="container2">
          <p className="textBlog1">
            Bienvenue sur le blog de dvpariege ! <br /> <br />
            Ce blog est un espace pour partager mes passions et mes découvertes.{" "}
            <br /> Je m'intéresse à de nombreux sujets, du développement web à
            la cuisine en passant par l'artisanat, la musique, le jeu vidéo, les
            voyages etc. <br /> Je suis toujours à la recherche de nouvelles
            choses à apprendre et à partager.
          </p>
          <a className="aButtonBlog" href="/blogdvpariege">
            <p className="buttonBlog">voir le blog </p>
          </a>
        </div>
        <div className="container1Template">
          <p className="soustexteBlog">dvpariege</p>
          <h1 className="h1ADecouvrir">Notre Premier Template !!!</h1>
          <p className="pADecouvrir">Le template basic est arrivé </p>
          <img
            src={exempletemplate1}
            alt="template basic dvpariege "
            className="imgTemplate1"
          />
          <div className="textTemplateButtonContainer">
            <p className="pADecouvrirTemplate">
              Notre premier template est en vente, il vous permet de mettre en
              avant votre travail et votre entreprise ou même vos projets
              personnels à moindre coût <br />
              <br />
              contenu du template :
              <br />
              -un menu dynamique <br />
              -Une page d'accueil disposant de trois menus <br />
              -Une page de présentation de vos services <br />
              -une page dédiée à vos images <br />
              -une page contact <br />
              -une vidéo sur le thème de votre choix <br /> <br />
              vous pouvez obtenir ce template + la
              maintenance et la mise à jour du site pour un total de
              <br /> 30 euros par mois  
            </p>
            <a className="aButtonTemplate" href="/template">
              <p className="buttonTemplateDvpAriege">
              Voir un exemple de nos modèles de sites à 30€ par mois.
              </p>
              <p className="pADecouvrirTemplate">
              Pour toute question ou pour avoir un devis, contactez-moi à l'adresse suivante : dvpariege@gmail.com.
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="grilleContainerDvpAriege">
        <img className="logoDvpGrille" src={logodvp} alt="img logodvp" />
        <GrilleAccueil />
      </div>
      <Footer />
    </div>
  );
};

export default MenuPrincipal;
